<template>
  <div class="m-recipe-card">
    <div class="m-recipe-card__img-wrap">
      <SfLink class="sf-product-card__link" link="#">
        <SfImage
          :src="recipe.image"
          class="m-recipe-card__img"
          height="193"
          width="290"
          alt="image"
          lazy
        />
      </SfLink>
      <div class="m-recipe-card__details">
        <span>{{ recipe.details.time }}</span>
        <span>{{ recipe.details.ingredients }}</span>
      </div>
    </div>
    <div class="m-recipe-card__title">
      {{ recipe.title }}
    </div>
  </div>
</template>
<script>
import {
  SfImage,
  SfLink
} from '@storefront-ui/vue';
export default {
  name: 'MRecipeCard',
  components: {
    SfImage,
    SfLink
  },
  props: {
    recipe: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.m-recipe-card {
  background: transparent;
  line-height: 0;

  .sf-image {
    max-width: 100%;
  }

  .sf-image img{
    object-fit: cover;
    width: 100%;
  }
  &__desc{
    position: relative;
    line-height: normal;
  }
  &__link{
    height: 100%;
  }
  &__img-wrap{
    position: relative;
    z-index: 0;
  }
  &__details {
    font-size: var(--font-size-13);
    display: flex;
    padding: 10px;
    position: absolute;
    left: 10px;
    bottom: 10px;
    line-height: normal;
    z-index: 0;
    transform: translate3d(0,0,0);

    @media (min-width: $tablet-min){
      left: 15px;
      bottom: 15px;
      padding: 15px;
    }

    &:before {
      content: '';
      position: absolute;
      border-radius: 10px;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.8);
      z-index: -1;
      user-select: none;
      pointer-events: none;

      @supports ((-webkit-backdrop-filter: blur(4px)) or (backdrop-filter: blur(4px))) {
        background-color: rgba(255, 255, 255, 0.8);
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
      }
    }

    span {
      position: relative;
      z-index: 0;
      transform: translate3d(0,0,0);

      &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        top: 0;
        bottom: 0;
        margin: 0 10px;
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        background: var(--orange);
      }

      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }
  &__title{
    font-size: var(--font-size-13);
    line-height: normal;
    padding: 8px 10px;
    min-height: 52px;
    background: #E9F3F9;
    position: relative;;
    @media (min-width: $tablet-min) {
      font-size: var(--font-size-15);
      min-height: 58px;
      padding: 8px 15px;
    }
  }
}
</style>
