<template>
  <SfCarousel
    class="slider-carousel m-recipe-carousel"
    :settings="sliderSettings"
  >
    <template #prev="{go}">
      <SfButton
        class="sf-arrow sf-arrow--rounded sf-arrow-prev"
        :class="`sf-arrow--${arrowColor}`"
        aria-label="previous"
        @click="go('prev')"
      />
    </template>
    <SfCarouselItem
      v-for="(recipe, i) in recipeList"
      :key="i"
    >
      <MRecipeCard :recipe="recipe" />
    </SfCarouselItem>
    <template #next="{go}">
      <SfButton
        class="sf-arrow sf-arrow--rounded sf-arrow-next"
        :class="`sf-arrow--${arrowColor}`"
        aria-label="next"
        @click="go('next')"
      />
    </template>
  </SfCarousel>
</template>
<script>
import { SfCarousel, SfButton } from '@storefront-ui/vue';
import MRecipeCard from './m-recipe-card';

export default {
  name: 'MRecipeCarousel',
  components: {
    MRecipeCard,
    SfCarousel,
    SfButton
  },
  props: {
    products: {
      type: Array,
      default: () => []
    },
    sliderSettings: {
      type: Object,
      default: () => {
        return {
          type: 'slider',
          animationDuration: 400,
          animationTimingFunc: 'ease-in-out',
          perView: 4,
          gap: 10,
          breakpoints: {
            1023: {
              perView: 2,
              peek: {
                before: 0,
                after: 100
              }
            },
            767: {
              perView: 1,
              gap: 5,
              peek: {
                before: 0,
                after: 100
              }
            }
          }
        }
      }
    },
    arrowColor: {
      type: String,
      default: 'gray'
    }
  },
  data () {
    return {
      recipeList: [
        {
          id: 2816,
          image: 'https://demo.vuestorefront.io/img/324/489/resize/m/p/mp08-blue_main_2.jpg',
          link: '/zeppelin-yoga-pant.html?childSku=MP08-32-Blue',
          parentId: 2828,
          qty: 30,
          sku: 'MP08-32-Blue',
          title: 'Zeppelin Yoga Pant',
          type_id: 'configurable',
          details: {
            time: '30 минут',
            ingredients: '8 ингридиентов'
          }
        },
        {
          id: 44,
          image: 'assets/recipe/recipe1.jpg',
          link: '/didi-sport-watch.html',
          parentId: 44,
          qty: 22,
          sku: '24-WG02',
          title: 'Didi Sport Watch',
          type_id: 'simple',
          details: {
            time: '40 минут',
            ingredients: '8 ингридиентов'
          }
        },
        {
          id: 2816,
          image: 'https://demo.vuestorefront.io/img/324/489/resize/m/p/mp08-blue_main_2.jpg',
          link: '/zeppelin-yoga-pant.html?childSku=MP08-32-Blue',
          parentId: 2828,
          qty: 30,
          sku: 'MP08-32-Blue',
          title: 'Zeppelin Yoga Pant',
          type_id: 'configurable',
          details: {
            time: '50 минут',
            ingredients: '8 ингридиентов'
          }
        },
        {
          id: 44,
          image: 'assets/recipe/recipe1.jpg',
          link: '/didi-sport-watch.html',
          parentId: 44,
          qty: 22,
          sku: '24-WG02',
          title: 'Didi Sport Watch',
          type_id: 'simple',
          details: {
            time: '60 минут',
            ingredients: '8 ингридиентов'
          }
        },
        {
          id: 2816,
          image: 'assets/recipe/recipe1.jpg',
          link: '/zeppelin-yoga-pant.html?childSku=MP08-32-Blue',
          parentId: 2828,
          qty: 30,
          sku: 'MP08-32-Blue',
          title: 'Zeppelin Yoga Pant',
          type_id: 'configurable',
          details: {
            time: '70 минут',
            ingredients: '8 ингридиентов'
          }
        }
      ]
    }
  }
};
</script>
