import { render, staticRenderFns } from "./m-recently-viewed-products.vue?vue&type=template&id=aa07c9d6&scoped=true"
import script from "./m-recently-viewed-products.vue?vue&type=script&lang=js"
export * from "./m-recently-viewed-products.vue?vue&type=script&lang=js"
import style0 from "./m-recently-viewed-products.vue?vue&type=style&index=0&id=aa07c9d6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa07c9d6",
  null
  
)

export default component.exports