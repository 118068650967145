import { render, staticRenderFns } from "./m-recipe-carousel.vue?vue&type=template&id=802a5aa4"
import script from "./m-recipe-carousel.vue?vue&type=script&lang=js"
export * from "./m-recipe-carousel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports